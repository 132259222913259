import type { UseFetchOptions } from '#app'
import type { MaybeRefOrGetter } from 'vue'
import { defu } from 'defu'
import type { ApiResponse } from '~/types/api.type'
import type { Client } from '~/types/client.type'
import type { Employee } from '~/types/employee.type'
import type { Project } from '~/types/project.type'
import type { TaskList } from '~/types/taskList.type'
import type { TimeEntry } from '~/types/timeEntry.type'

export interface ApiTimeEntryGetListParams {
  employeeId?: Employee['id']
  projectId?: Project['id']
  timeIntervalStartAt: Date
  timeIntervalEndAt: Date
  withPending?: boolean
  onlyPending?: boolean
  clientId?: Client['id']
  onlyUnbilled?: boolean
  onlyBillable?: boolean
  withEmployee?: boolean
}

export type ApiTimeEntryCreateParams = Pick<TimeEntry, 'employeeId' | 'startAt' | 'endAt' | 'taskId' | 'notes' | 'isBilled'>
export type ApiTimeEntryCreatePendingParams = Omit<ApiTimeEntryCreateParams, 'startAt' | 'endAt' | 'employeeId'>
export type ApiTimeEntryUpdateParams = Pick<Partial<TimeEntry>, 'startAt' | 'endAt' | 'taskId' | 'isBilled' | 'notes' | 'employeeId'>

type ApiTimeEntry = TimeEntry<'withTask' | 'withProject' | 'withEmployee'>
type ApiTimeEntryPending = ApiTimeEntry & { taskList: TaskList<undefined> }

export function useApiTimeEntryGetList<T extends ApiTimeEntryGetListParams>(params: MaybeRefOrGetter<T> = {} as T, options: UseFetchOptions<ApiResponse<ApiTimeEntry[]>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntry[]>> = { method: 'GET', params, immediate: false, watch: false }
  return useApiFetch('/time-entries', defu(notOverridableOptions, options))
}

export function useApiTimeEntryGetById(id: MaybeRefOrGetter<TimeEntry['id']>, options: UseFetchOptions<ApiResponse<ApiTimeEntry>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntry>> = { method: 'GET' }
  return useApiFetch(() => `/time-entries/${toValue(id)}`, defu(notOverridableOptions, options))
}

export function useApiTimeEntryGetPending(options: UseFetchOptions<ApiResponse<ApiTimeEntry>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntry>> = { method: 'GET' }
  return useApiFetch('/time-entries/pending', defu(notOverridableOptions, options))
}

export function useApiTimeEntryCreate(timeEntry: MaybeRefOrGetter<ApiTimeEntryCreateParams>, options: UseFetchOptions<ApiResponse<ApiTimeEntry>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntry>> = { method: 'POST', body: timeEntry }
  const overridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntry>> = { immediate: false, watch: false }
  return useApiFetch('/time-entries', defu(notOverridableOptions, options, overridableOptions))
}

export function useApiTimeEntryCreatePending(timeEntry: MaybeRefOrGetter<ApiTimeEntryCreatePendingParams>, options: UseFetchOptions<ApiResponse<ApiTimeEntryPending>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntryPending>> = { method: 'POST', body: timeEntry }
  const overridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntryPending>> = { immediate: false, watch: false }
  return useApiFetch('/time-entries/pending/start', defu(notOverridableOptions, options, overridableOptions))
}

export function useApiTimeEntryUpdateById(id: MaybeRefOrGetter<TimeEntry['id']>, timeEntry: MaybeRefOrGetter<ApiTimeEntryUpdateParams> = {}, options: UseFetchOptions<ApiResponse<ApiTimeEntry>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntry>> = { method: 'PATCH', body: timeEntry }
  const overridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntry>> = { immediate: false, watch: false }
  const url = computed(() => {
    return `/time-entries/${toValue(id)}`
  })
  return useApiFetch(url, defu(notOverridableOptions, options, overridableOptions))
}

export function useApiTimeEntryStopPending(options: UseFetchOptions<ApiResponse<ApiTimeEntryPending>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntryPending>> = { method: 'PUT' }
  const overridableOptions: UseFetchOptions<ApiResponse<ApiTimeEntryPending>> = { immediate: false, watch: false }

  return useApiFetch('/time-entries/pending/stop', defu(notOverridableOptions, options, overridableOptions))
}

export function useApiTimeEntryDeleteById(id: MaybeRefOrGetter<TimeEntry['id']>, options: UseFetchOptions<never> = {}) {
  const notOverridableOptions: UseFetchOptions<never> = { method: 'DELETE' }
  const overridableOptions: UseFetchOptions<never> = { immediate: false, watch: false }
  return useApiFetch(`/time-entries/${toValue(id)}`, defu(notOverridableOptions, options, overridableOptions))
}
