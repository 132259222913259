import { default as _91_91view_93_93MTbiyyYH4qMeta } from "/frontend/pages/[[view]].vue?macro=true";
import { default as _91view_93bO6DhRMmpfMeta } from "/frontend/pages/clients/[id]/[view].vue?macro=true";
import { default as indexZNImBqURvgMeta } from "/frontend/pages/clients/index.vue?macro=true";
import { default as _91id_931ghKna1jqaMeta } from "/frontend/pages/invoices/[id].vue?macro=true";
import { default as indexRMY5QbvRTAMeta } from "/frontend/pages/invoices/index.vue?macro=true";
import { default as _91view_93v17oCt1eQ1Meta } from "/frontend/pages/projects/[id]/[view].vue?macro=true";
import { default as indexNaDLVIeT5DMeta } from "/frontend/pages/projects/index.vue?macro=true";
import { default as _91id_93WnCGuXavewMeta } from "/frontend/pages/recurring-invoices/[id].vue?macro=true";
import { default as indexx6PkNpljY5Meta } from "/frontend/pages/recurring-invoices/index.vue?macro=true";
import { default as _91view_9385si73iUzsMeta } from "/frontend/pages/reports/[id]/[view].vue?macro=true";
import { default as index2pY3WYXzcDMeta } from "/frontend/pages/reports/index.vue?macro=true";
import { default as settingsTwZpRb2Y8IMeta } from "/frontend/pages/settings.vue?macro=true";
import { default as timesheetsFqYa0EYsv8Meta } from "/frontend/pages/timesheets.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/:view?",
    meta: _91_91view_93_93MTbiyyYH4qMeta || {},
    component: () => import("/frontend/pages/[[view]].vue").then(m => m.default || m)
  },
  {
    name: "clients-id-view",
    path: "/clients/:id()/:view()",
    meta: _91view_93bO6DhRMmpfMeta || {},
    component: () => import("/frontend/pages/clients/[id]/[view].vue").then(m => m.default || m)
  },
  {
    name: "clients",
    path: "/clients",
    component: () => import("/frontend/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    component: () => import("/frontend/pages/invoices/[id].vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/frontend/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-id-view",
    path: "/projects/:id()/:view()",
    meta: _91view_93v17oCt1eQ1Meta || {},
    component: () => import("/frontend/pages/projects/[id]/[view].vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/frontend/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "recurring-invoices-id",
    path: "/recurring-invoices/:id()",
    component: () => import("/frontend/pages/recurring-invoices/[id].vue").then(m => m.default || m)
  },
  {
    name: "recurring-invoices",
    path: "/recurring-invoices",
    component: () => import("/frontend/pages/recurring-invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-id-view",
    path: "/reports/:id()/:view()",
    component: () => import("/frontend/pages/reports/[id]/[view].vue").then(m => m.default || m)
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/frontend/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/frontend/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "timesheets",
    path: "/timesheets",
    component: () => import("/frontend/pages/timesheets.vue").then(m => m.default || m)
  }
]